
import { Component, Vue } from 'vue-property-decorator'
import Drawer from '@/layouts/components/Drawer.vue'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { namespace } from 'vuex-class'
const AuthStore = namespace('AuthStore')
const UserStore = namespace('UserStore')
import { UserModel } from '@/core/models/UserModel'

@Component({
  name: 'ProfileLayout',
  components: {
    Drawer,
    Modal,
    BaseButton,
  },
})
export default class extends Vue {
  @AuthStore.Getter
  public isAuthComplete!: boolean

  @UserStore.Getter
  public isAuth!: boolean

  @UserStore.Action
  private getUser!: () => Promise<UserModel>

  @UserStore.Getter
  public currentUser!: UserModel

  showWarning = false

  public async created(): Promise<void> {
    await this.getUser()
    if (
      this.isAuthComplete &&
      !this.currentUser.billing_plan?.current &&
      this.currentUser.billing_plan?.next
    ) {
      this.showWarning = true
    }
  }

  public mounted(): void {
    /*if (!this.isAuth) {
      this.$router.push({ name: 'Login' })
    }*/

    const s = document.createElement('script')
    s.async = true
    s.src =
      'https://cdn-ru.bitrix24.ru/b13775582/crm/site_button/loader_2_pucfpy.js' +
      '?' +
      ((Date.now() / 60000) | 0)
    const h = document.getElementsByTagName('script')[0]
    h?.parentNode?.insertBefore(s, h)
  }
}
